import Dexie, { Table } from 'dexie'
import { iSite } from '@/interfaces'


export class historyClass extends Dexie {
    states!: Table<iSite>
     constructor() {
        super('dataHistory')
        this.version(2).stores({
            states: 'id'
        })
     }

    async addPage( state:iSite ) {
        try {
            // await console.log(page)
            await console.log( 'state', state)
            await this.states.add( state )
        } catch (error) {
            await console.log(error)
        }
    }

    async loadData() {
        const state = await this.states.orderBy('id').last()
        // console.log('lastLoaded',page )
        return state
     }

    async getState(id:number) {
        const state = await this.states.get(id)
        await console.log(state)
        return state
    }


}
