import { createPinia } from "pinia"
import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'

import App from './App.vue'
import './registerServiceWorker'
import './sass/bootstrap.scss'
import './sass/grid.scss'
import './sass/app.scss'
import 'prosemirror-view/style/prosemirror.css'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(Notifications)
app.mount('#app')
