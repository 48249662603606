import { defineStore } from 'pinia'
import { nextTick } from 'vue'
import { iPage, iSection, iShadow, iBlock, iSite } from '@/interfaces'
import { iSpacer} from '@/interface_blocks'
import { iBackground, iBorder, iColor, iGrid, iHeight, iPadding,
         iPageTypo, iShadowBox, iTransition, iTypo } from '@/interface_content'
import { historyClass } from '@/indexDB/idxDB-history'
import { notify } from "@kyvg/vue3-notification";

import defaultPage from '../json/defaultPage.json'
import defaultSection from '../json/defaultSection.json'
import defaultBlock from '../json/defaultBlock.json'

const hty = new historyClass()

export const siteStore = defineStore('site', {

    state: () => {
        return {
            site: {
                id: 0 as number,
                siteStyle: {
                    fonts: [] as string[],
                    grid: {
                        fullWidth: false,
                        gridWidth: "1200",
                    },
                    background: {
                        backgroundType: "none",
                        backgroundColor: "#ffffff",
                        backgroundImage: "url(https://picsum.photos/600)",
                        backgroundPosition: "center center",
                        backgroundSize: "normal",
                        backgroundRepeat: true,
                        backgroundGradientA: "#ffffff",
                        backgroundGradientB: "#aaaaaa",
                        backgroundGradientDeg: "180"
                    },
                    colors: {
                        primary: "#DC291C",
                        secondary: "#bbbbbb",
                        accent: "#a8005c",
                        textOnLight: "#333333",
                        textOnDark: "#eeeeee"
                    },
                    typography: {
                        mainTypo: "Montserrat",
                        mainTypoWeight: "500",
                        headerTypo: "Moul",
                        headerTypoWeight: "400",
                        baseSize: "1.5",
                        baseLineHeight: "1.8",
                        headeScale: "1",
                        mobileHeadeScale: "1.9",
                    },

                },
                pages: [] as iPage[],
            } as iSite,

            WebFont : require('webfontloader'),

            currentPage: 0 as number,

            currentSection: 0 as number,
            currentOSection: {} as iSection,
            currentBlockID: 0 as number,

            editBlockSection: {} as iSection,
            editBlock: {} as iBlock,
            editSection: 0 as number,
            clipBoardBlock: {} as iBlock,
            clipBoardSection: {} as iSection,

            waitNewBlock: {} as iBlock,

            newTextEditorID: 0 as number,

            gridxy: [] as number[],
            window_gridxy: [] as number[],
            shadowsections: [] as iShadow[],

            REACTChangeSiteStyle: 0 as number,
            REACTChangeSectionStyle: 0 as number,
            REACTChangeBlockStyle: 0 as number,
            REACTChangeChromaStyle: 0 as number,
            REACTChangePage: 0 as number,

            REACTChangeSpacerStyle: 0 as number,

            REACTcloseNewSection: 0 as number,
            REACTNewBlock: 0 as number,
            REACTStartApp: 0 as number,
            REACTHistory: 0 as number,
            REACTUndo: 0 as number,

            reactMOVE: 0 as number,
            reactLINES: 0 as number,
            reactOpenNEW: 0 as number,
            
            windowWidth: 0 as number,

        }
    },

    actions: {


        getsizes() {
            const gridWidth = Number( this.site.siteStyle.grid.gridWidth )
            let totalBlockSize =  (gridWidth / 12) + 1.66666
            let fullTotalBlockSize =  (this.windowWidth / 12) + .33333
            totalBlockSize = ~~totalBlockSize
            fullTotalBlockSize = ~~fullTotalBlockSize
            this.window_gridxy = []
            let tot = fullTotalBlockSize
            for (let v = 1; v < 24; v++) {
                this.window_gridxy.push(tot)
                tot += fullTotalBlockSize
            }
            this.gridxy = []
            tot = totalBlockSize
            for (let v = 1; v < 24; v++) {
                this.gridxy.push(tot)
                tot += totalBlockSize
            }
        },


        // PAGES -------------------------------------------------
        addPage() {
            const newPage = JSON.parse(JSON.stringify(defaultPage)) as iPage
            newPage.id = this.newPageID()
            newPage.name = 'Página ' + newPage.id
            newPage.sections[0].id = this.newSectionID()
            this.site.pages.push( newPage )
            const index = this.site.pages.findIndex(page => page.id === newPage.id)
            this.currentPage = index
            this.REACTHistory++
            this.REACTChangePage++
         },

        changePage(id:number) {
            const index = this.site.pages.findIndex(page => page.id === id)
            this.currentPage = index
            this.REACTChangePage++
            this.reactLINES++
        },


        deletePage(id:number) {
            const index = this.site.pages.findIndex(page => page.id === id)
            this.site.pages.splice(index, 1)
            this.currentPage = 0
            this.REACTHistory++
        },


        duplicatePage(page: iPage) {
            const newID = this.newPageID()
            console.table(newID)
            page = JSON.parse(JSON.stringify(page))
            page.id = newID
            page.name = 'Copia de ' + page.name

            page.sections.forEach((section:iSection) => {
                section.id = this.newSectionID()
                section.blocks.forEach((block:iBlock) => {
                    block.id = this.newBlockID()
                })
            })

            this.site.pages.push(page)
            const index = this.site.pages.findIndex(page => page.id === newID)
            this.currentPage = index
            this.REACTHistory++
            return newID
        },


        // CLIPBOARDS ---------------------------

        copyBlock() {
            this.clipBoardBlock = this.editBlock
            notify({ text: 'Bloque copiado', })
        },

        copySection() {
            this.clipBoardSection = this.currentOSection
            notify({ text: 'Sección copiada', })
        },


        // ID generators ------------------------------------------

        newPageID() :number{
            let strn = Number( localStorage.getItem('pageID') )
            if(strn==undefined) strn = 0
            strn++
            localStorage.setItem('pageID',  String(strn) )
            return strn
        },

        newSectionID() :number {
            let strn = Number( localStorage.getItem('sectionID') )
            if(strn==undefined) strn = 0
            strn++
            localStorage.setItem('sectionID',  String(strn) )
            return strn
        },

        newBlockID() :number{
            let strn = Number( localStorage.getItem('blockID') )
            if(strn==undefined) strn = 0
            strn++
            localStorage.setItem('blockID',  String(strn) )
            return strn
        },




        // HISTORY -------------------------------------------------


        async loadHistoryLast() {
            const last = await hty.loadData() as iSite
            if( last!=undefined ) {
                this.site = last
            } else {
                const defPage = JSON.parse(JSON.stringify(defaultPage)) as iPage
                defPage.id = this.newPageID()
                this.site.pages.push( defPage )
                const index = this.site.pages.findIndex(page => page.id === defPage.id);
                this.currentPage = index
            }
            this.REACTStartApp++
        },

        async undo() {
            const state = await hty.getState( this.site.id - 1 ) as iSite | undefined
            if ( state != undefined ) {
                this.site = state
                this.REACTUndo++
            }
        },

        async redo() {
            const state = await hty.getState( this.site.id + 1 ) as iSite | undefined
            if ( state != undefined ) {
                this.site  = state
                this.REACTUndo++
            }
        },


        // SITE STYLES -------------------------------------------------

        applySiteBackground(background: iBackground) {
            this.site.siteStyle.background = background
            this.REACTChangeSiteStyle++
            this.REACTChangeChromaStyle++
        },

        applySiteColors(colors: iColor) {
            this.site.siteStyle.colors = colors
            this.REACTChangeSiteStyle++
        },

        applySiteTypos(typos: iPageTypo) {
            this.site.siteStyle.typography = typos
            nextTick(() => {
                this.pageFontActivation()
                this.REACTChangeSiteStyle++
            })
        },

        applySiteGrid(grid: iGrid) {
            const antWid = this.site.siteStyle.grid.gridWidth
            const newWid = grid.gridWidth
            nextTick(() => {
                this.site.pages.forEach(function (page: iPage) {
                    page.sections.forEach(function (section: iSection) {
                        if (section.sectionStyle.grid.gridWidth == antWid) {
                            section.sectionStyle.grid.gridWidth = newWid
                        }
                    })
                })
                this.REACTChangeSiteStyle++
                this.site.siteStyle.grid = grid
                this.getsizes()
                this.reactLINES++
            })
        },



        // SECTION STYLES -------------------------------------------------

        applySectionBackground( background: iBackground ) {
            const sect = this.site.pages[this.currentPage].sections
                .find(({id}) => id === this.currentOSection.id) as iSection
            sect.sectionStyle.background = background
            this.REACTChangeSectionStyle++
        },

        applySectionPadding( padding: iPadding ) {
            const sect = this.site.pages[this.currentPage].sections
                .find(({id}) => id === this.currentOSection.id) as iSection
            sect.sectionStyle.padding = padding
            this.REACTChangeSectionStyle++
        },

        applySectionHeight( height: iHeight ) {
            const sect = this.site.pages[this.currentPage].sections
                .find(({id}) => id === this.currentOSection.id) as iSection
            sect.sectionStyle.height = height
            this.REACTChangeSectionStyle++
        },

        applySectionGrid( grid: iGrid ) {
            const sect = this.site.pages[this.currentPage].sections
                .find(({id}) => id === this.currentOSection.id) as iSection
            if(grid.gridWidth != this.site.siteStyle.grid.gridWidth) {
                grid.changed = true
            }
            sect.sectionStyle.grid = grid
            this.REACTChangeSectionStyle++
        },


        // BLOCK STYLES -------------------------------------------------


        setBlockEditing( sect: number, idb: number ) {
            this.editBlockSection = this.site.pages[this.currentPage].sections
                .find(({id}) => id === sect) as iSection
            this.editBlock = this.editBlockSection.blocks
                .find(({id}) => id === idb) as iBlock
         },

        setSectionEditing( sect: number ) {
            const section = this.site.pages[this.currentPage].sections
                .find(({id}) => id === sect) as iSection
            this.currentOSection = section
        },

        //----------

        applyBlockBorder( border: iBorder ) {
            this.editBlock.blockStyle.border = border
            this.REACTChangeBlockStyle++
        },

        applyBlockPadding( padding: iPadding ) {
            this.editBlock.blockStyle.padding = padding
            this.REACTChangeBlockStyle++
        },

        applyTypo( typo: iTypo ) {
            this.editBlock.blockStyle.typo  = typo
            this.editBlock.blockStyle.typo.default  = false
            if (typo.fontFamily != 'default') {
                this.fontActivation()
            }
            this.REACTChangeBlockStyle++
        },


        applyBlockShadow( shadow: iShadowBox ) {
            this.editBlock.blockStyle.shadowBox = shadow
            this.REACTChangeBlockStyle++
        },

        applyBackground( background: iBackground ) {
            this.editBlock.blockStyle.background = background
            this.REACTChangeBlockStyle++
        },


        applyTransition( transition: iTransition ) {
            this.editBlock.blockStyle.transition = transition
            this.REACTChangeBlockStyle++
        },



        // SECTIONS & BLOCKS -------------------------------------------------

        deleteBlock(id_sec: number, id: number) {
            const section = this.site.pages[this.currentPage].sections
                .find(({id}) => id === id_sec) as iSection
            const indexOfObject = section.blocks.findIndex(object => {
                return object.id === id
            })
            section.blocks.splice(indexOfObject, 1)
            this.REACTHistory++
        },

        pasteSection(order: number) {
            const neworder: number = order + 1
            this.site.pages[this.currentPage].sections
                .forEach(function (section: iSection) {
                    if (section.order == neworder)
                        section.order++
                    else if (section.order > neworder)
                        section.order++
                })
            const newsection: iSection =  JSON.parse(JSON.stringify(this.clipBoardSection))
            newsection.id = this.newSectionID()
            newsection.order = order + 1,
            newsection.blocks.forEach((block:iBlock) => {
                block.id = this.newBlockID()
            })

            this.site.pages[this.currentPage].sections.push(newsection)
            this.sortSections()
            this.REACTcloseNewSection++
            this.REACTHistory++
        },


        newSection(order: number) {
            const neworder: number = order + 1
            this.site.pages[this.currentPage].sections
                .forEach(function (section: iSection) {
                if (section.order == neworder)
                    section.order++
                else if (section.order > neworder)
                    section.order++
            })
            const newsection = JSON.parse(JSON.stringify(defaultSection)) as iSection
            newsection.id = this.newSectionID()
            newsection.order = order + 1
            this.site.pages[this.currentPage].sections.push(newsection)
            this.sortSections()
            this.REACTcloseNewSection++
            this.REACTHistory++
        },

        deleteSection(id: number) {
            const indexOfObject = this.site.pages[this.currentPage].sections.findIndex(object => {
                return object.id === id
            })
            this.site.pages[this.currentPage].sections.splice(indexOfObject, 1);
            this.REACTHistory++
        },

        upSection(ids: number) {
            const sect = this.site.pages[this.currentPage].sections.find(({id}) => id === ids) as iSection
            if (sect.order > 1) {
                this.site.pages[this.currentPage].sections.forEach(function (section: iSection) {
                    if (section.order == sect.order - 1)
                        section.order++
                    else if (section.order == sect.order)
                        section.order--
                })
                this.sortSections()
                this.REACTHistory++
            }
        },


        downSection(ids: number) {
            const sect = this.site.pages[this.currentPage].sections.find(({id}) => id === ids) as iSection
            const lastSection = this.site.pages[this.currentPage].sections.slice(-1)[0]
            if (sect.order < lastSection.order) {
                const newOrder = sect.order + 1
                this.site.pages[this.currentPage].sections.forEach(function (section: iSection) {
                    if (section.order == newOrder)
                        section.order--
                    else if (section.order == sect.order)
                        section.order++
                })
                this.sortSections()
                this.REACTHistory++
            }
        },

        newFreeBlock(blockType: string) {
            const sect = this.site.pages[this.currentPage].sections.find(({id}) => id === this.currentSection) as iSection
            const shad = this.shadowsections.find(({id}) => id === this.currentSection) as iShadow
            sect.rows += 2
            shad.rows += 2
            this.currentBlockID = this.newBlockID()
            const defBlock = JSON.parse(JSON.stringify(defaultBlock))  as iBlock
            defBlock.id =  this.currentBlockID
            defBlock.btype = blockType
            defBlock.x = 1
            defBlock.y = sect.rows - 3
            defBlock.w = 4
            defBlock.h = sect.rows - 1
            sect.blocks.push( defBlock )
            this.REACTNewBlock++
        },


        sortSections() {
            this.site.pages[this.currentPage].sections.sort((a, b) => {
                return a.order - b.order;
            })
        },


        // Blocks SETTINGS -----------------------------------------------------------------------

        applySpacerSettings( spacer:iSpacer) {
            this.editBlock.dataBlock.spacer = spacer
            this.REACTChangeSpacerStyle++
        },



        // FONT -----------------------------------------------------------------------

        pageFontActivation() {
            const morefonts = [] as string[]
            morefonts.push(this.site.siteStyle.typography.mainTypo + ':ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,9')
            morefonts.push(this.site.siteStyle.typography.headerTypo + ':ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,9')
            this.WebFont.load({google: {families: morefonts}})
        },

        fontActivation() {
            const alltypos: string[] = []
            alltypos.push(this.site.siteStyle.typography.mainTypo)
            alltypos.push(this.site.siteStyle.typography.headerTypo)

            this.site.pages[this.currentPage].sections.forEach(function (section: iSection) {
                section.blocks.forEach(function (block: iBlock) {
                    alltypos.push(block.blockStyle.typo.fontFamily)
                })
            })

            let atypos = [...new Set(alltypos)]
            atypos = atypos.filter(function (item) {
                return item !== 'default'
            })

            this.site.siteStyle.fonts = atypos
            const morefonts = [] as string[]
            this.site.siteStyle.fonts.forEach(function (font: string) {
                morefonts.push(font + ':ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,9')
            })
            this.WebFont.load({ google: { families: morefonts  } })
        },
    },
})













