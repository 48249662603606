import { defineStore } from 'pinia'
export const offCanvasStore = defineStore('offCanvas', {
    state: () => {
        return {
            blockType: 'Spacer' as string,
            openOff: false as boolean,
            reactEditBlock: 0 as number,
        }
    },
    actions: {
        setComponent(tipo:string) {
            this.blockType = tipo
            this.reactEditBlock++
            this.openOff = true
        },
        closeComponent() {
            this.openOff = false
            this.blockType = ''
        }
    }
})