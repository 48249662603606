import Dexie, {Table} from 'dexie'
import { iFont } from '@/interface_content'


export class gFontsClass extends Dexie {
    fonts!: Table<iFont>
    constructor() {
        super('dataFont')
        this.version(1).stores({
            fonts: '++id, name, subsets'
        })
    }
    async addfont(name:string, variants:string) {
        try {
            await this.fonts.add({
                name: name,
                variants: variants,
            })
        } catch (error) {
            await console.log(error)
        }
    }
    async countfonts() {
        return await this.fonts.count()
    }
    async fontVariants(font: string ) {
        const fonte = await this.fonts.where({name: font }).toArray()
        return fonte[0].variants
    }

    async allFonts( ) {
       const fonte = await this.fonts.toArray()
        return fonte
    }


}
